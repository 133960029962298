<template>
  <div class="content">
    <Header>
      <div class="wrap">
        <div class="title">
          <div class="name">Freeland.ai</div>
          <div class="info">
            Your AI Companion is here to provide support, roleplay, share
            feelings or just talk about anything that’s on your mind.
          </div>
          <div class="downBtn">
            <a
              href="https://play.google.com/store/apps/details?id=com.chat.freeland"
              target="_blank"
              >Free Download</a
            >
          </div>
        </div>
        <div class="headimg">
          <img src="../img/headimg.png" alt="" />
        </div>
      </div>
    </Header>
    <div class="bg1" id="about">
      <div
        class="wrap wow animate__fadeInUp"
        data-wow-duration="0.5s"
        data-wow-delay="0s"
      >
        <div class="aboutus">
          <div class="pic"><img src="../img/aboutus.png" alt="" /></div>
          <div class="infotext">
            <div class="titlename" style="text-align: left">About Us</div>
            <div class="intro">
              Are you looking for a virtual companion who will be there for you
              no matter what? Do you want someone who understands your thoughts
              and feelings and can help you manage stress and anxiety? Just look
              no further than Freeland - your trusty virtual AI friend.
            </div>
            <div class="com">Freeland.ai</div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="bg2" id="products">
      <div
        class="wrap wow animate__fadeInUp"
        data-wow-duration="1s"
        data-wow-delay="0s"
      >
        <div class="products">
          <div class="titlename" style="margin-bottom: 70px">Products</div>
          <div class="info">
            <div class="productsitem">
              <div class="icon">
                <img class="productsimg" src="../img/tami.png" alt="" />
                <p>Tami</p>
              </div>
              <div class="infotext">
                Tami offers live streaming videos, you can join the video show
                with users, interact with the broadcasters. Meet users all over
                the world, receive and send virtual gifts.What are you waiting
                for? Join Tami, broadcast your life, show your talent, making
                friends easily.
              </div>
            </div>
            <div class="productsitem">
              <div class="icon">
                <img class="productsimg" src="../img/tamipro.png" alt="" />
                <p>Tami Pro</p>
              </div>

              <div class="infotext">
                Live Streaming: Tami Pro is an exciting way to interact with
                celebrities and users from everywhere. Virtual Gifts: Show your
                appreciation by sending broadcasters virtual gifts. There are
                lots of cool gifts to send, of different sizes. Beauty Cam:
                Built in Beauty Cam feature is a filter that enhances the light
                and appearance of the broadcasters.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <div class="bg3">
      <div
        class="letsplay wow animate__fadeInUp"
        data-wow-duration="1s"
        data-wow-delay="0s"
        id="contact"
      >
        <div class="titlename" style="margin-bottom: 55px">Let's Play NOW</div>
        <div class="imgswiper" @mousewheel="slidepic" ref="imgswiper">
          <ul class="piclist piclist1">
            <li class="picitem" v-for="(item, index) in picList" :key="index">
              <img :src="item" alt="" />
            </li>
          </ul>
          <ul class="piclist piclist2">
            <li class="picitem" v-for="(item, index) in picList" :key="index">
              <img :src="item" alt="" />
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="bg4" id="contactus">
      <div
        class="wrap wow animate__fadeInUp"
        data-wow-duration="0.5s"
        data-wow-delay="0s"
      >
        <div class="contu">
          <ContactUs></ContactUs>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ContactUs from '@/components/ContactUs/ContactUs.vue'
export default {
  components: {
    ContactUs,
  },
  watch: {
    $route: {
      handler(val) {
        if (this.$route.query['about']) {
          this.gotocontent('about')
        }
        if (this.$route.query['products']) {
          this.gotocontent('products')
        }
        if (this.$route.query['contactus']) {
          this.gotocontent('contactus')
        }
      },
    },
  },
  data() {
    return {
      picList: [
        require('../img/pic1.png'),
        require('../img/pic2.png'),
        require('../img/pic3.png'),
        require('../img/pic4.png'),
        require('../img/pic1.png'),
      ],
    }
  },
  mounted() {
    new this.$wow.WOW({
      live: true,
    }).init()
    if (this.$route.query['about']) {
      this.gotocontent('about')
    }
    if (this.$route.query['products']) {
      this.gotocontent('products')
    }
    if (this.$route.query['contactus']) {
      this.gotocontent('contactus')
    }
  },
  methods: {
    slidepic() {
      const imgswiper = this.$refs.imgswiper
      console.log(imgswiper.scrollLeft)
      if (imgswiper.scrollLeft >= 1800) {
        imgswiper.scrollLeft = 1
      }
      if (imgswiper.scrollLeft <= 0) {
        imgswiper.scrollLeft = 1799
      }
      console.log(imgswiper.scrollLeft)
    },
    gotocontent(data) {
      document.getElementById(`${data}`).scrollIntoView()
      this.$router.push('/')
    },
  },
}
</script>

<style lang="scss" scoped src="./content.scss"></style>
