<template>
  <div class="content">
    <Header>
      <div class="wrap">
        <div class="title">
          <div class="name">Privacy Policy</div>
        </div>
      </div>
    </Header>
    <div class="txtWrap">
      <br />
      Welcome to Freeland! We respect and value your privacy.<br />
      The use of Freeland through our mobile applications may involve collection
      and use of your information. This Privacy Policy explains and informs you
      how your information is collected, used and disclosed by Freeland with
      respect to your access and use of our services through our mobile
      applications. It is important for you to understand this Privacy Policy
      and how you may protect your information, so please read this Privacy
      Policy carefully.<br />
      Application Scope of this Privacy Policy<br />
      By using Freeland, you agree that we may access, collect, use and share
      your information in accordance with this Privacy Policy, as revised from
      time to time. In addition, your use of Freeland signifies that you agree
      with the terms of this Privacy Policy. If you do not agree to the terms of
      this Privacy Policy, do not use Freeland.<br />
      This Privacy Policy is incorporated into and forms part of the Freeland
      Terms of Service that you have agreed to in order to use Freeland. Any
      terms used in this Privacy Policy will have the same meaning as the
      equivalent defined terms in the Freeland Terms of Service unless otherwise
      defined in this Privacy Policy or the context requires otherwise. Unless
      otherwise specified in this Privacy Policy, these terms in the Freeland
      Terms of Service shall also be applicable in this Privacy Policy.<br />
      Any information that is collected is subject to the Privacy Policy in
      effect at the time such information is collected. We may, however, modify
      and revise our Privacy Policy from time to time. If we make any change to
      this policy, which we deem to be material changes, we will (where
      reasonably practicable) notify you of such changes by posting them on our
      website, through our services or by sending you an email or other
      notification, and we will indicate when such changes will become
      effective. By continuing to access or use our services after the changes
      become effective, with or without notification, you are agreeing to be
      bound by the revised policy.<br />
      Freeland may link you to social media or other features or services
      (including websites, plug-ins and widgets) provided by a third party.
      Please note that this Privacy Policy doesn’t apply to the information
      collected by any third-party websites, services or applications that can
      be accessed through our services, and does not apply to the information
      collected by other companies and organizations who advertise their
      services on Freeland. We bear no liability for any third party use of any
      information provided by you to them.<br />
      Information You Provide and We Collect<br />
      2.1 To provide the services of Freeland, we access, collect, store and use
      the following Information relating to you:<br />
      1) any information included in your Freeland profile which are visible to
      other users of Freeland, or information you make available to us via your
      use of Freeland, which may include your photos, gender, and city or
      area;<br />
      2) information about your and others’ usage of and interaction with the
      service and our application, including usage by geographies, device and
      connection information, IP address, device capability, bandwidth,
      statistics on page views, and network type;<br />
      3) information we collect as you use Freeland, which may include:<br />
      ✓ technical information, such as your mobile carrier-related information,
      configuration information made available by other programs you use to
      access Freeland, your IP address, your location/country/region/time zone,
      your device's version and identification number, user settings, and the
      operating system;<br />
      ✓ information about the likes and dislikes you have made using
      Freeland.<br />
      2.2 Special Note about Sensitive Personal Information<br />
      To provide certain features (e.g. online video calling), we must access
      your microphone, camera, or location, with your permission, as described
      below:<br />
      1) Microphone: We access the microphone on your device (with your
      permission) to record audio messages and deliver sound during video
      calls.<br />
      2) Camera: We access the camera on your device (with your permission) to
      take your profile pictures and deliver realtime images during video
      calls.<br />
      3) Location: We display your current location on your profile page (with
      your permission) to provide personalized and optimized experience for you
      and other users.<br />
      By using Freeland, you agree that all processing activities of sensitive
      personal information are for the purposes and in the manner as described
      in this Privacy Policy.<br />
      Please note that we do not use your sensitive personal information for the
      purpose of making direct marketing unless agreed by you.<br />
      2.3 Special Note about Children’s Information<br />
      The services of Freeland are not for anyone under the age of 【18】, and
      anyone under the specified minimum age may not create a Freeland account
      or use Freeland services. Hence, we do not knowingly collect personal
      information from any data subject under such age. If we learn that we have
      collected personal information of a data subject under 【18】, we will
      take steps to delete such information from our files as soon as
      possible.<br />
      How We Use the Information We Collect<br />
      3.1 Purposes<br />
      We may use your Information for any of the following purposes:<br />
      1) to provide services to you (including both the services currently
      provided by us via Freeland and the services to be rendered by us via
      Freeland in the future);<br />
      2) to personalize and improve our services and your experiences when you
      use our services by, among other things, suggesting friends or profile
      information, or customizing the content we show you;<br />
      3) to respond to inquiries, for the purposes of customer service,
      security, archival and backup purposes in connection with providing
      services of Freeland;<br />
      4) to monitor and analyze usage and trends of our services;<br />
      5) to enhance the safety and security of our products and services;<br />
      6) to verify your identity and prevent fraud or other unauthorized or
      illegal activity;<br />
      7) to enforce our Terms of Use and other usage policies.<br />
      Freeland allows you to share text messages, photos, screenshots, videos
      and other communications in the Freeland application with other users, and
      if you choose to do so your text messages, photos, screenshots, videos and
      other communications will be stored on our servers. In addition, if you
      choose to share these items with other Freeland users, we may not be able
      to remove them from our servers or make them unavailable to anyone you
      have shared them with. Sending and sharing text messages, photos,
      screenshots, videos or other communications in the Freeland application is
      your decision. By choosing to share that information, you should
      understand that you may no longer be able to control how that information
      is used and that it may become publicly available (depending in part on
      your actions or the actions of others with whom you have shared the
      information). Freeland is not responsible for any use or misuse of
      information you share.<br />
      3.2 Transfer and Storage<br />
      We operate and may continue to operate servers in a number of
      jurisdictions around the world, so the server on which your personal
      information is used and stored may not be in your jurisdiction.<br />
      You consent to the transfer of your personal information (both inside and
      outside of your jurisdiction) for the purposes described in this Privacy
      Policy.<br />
      3.3 Sharing<br />
      Your information is shared with others in the following ways:<br />
      Sharing on our products<br />
      1) Public information in your profile can be seen by anyone. Please do not
      post or add personal data to your profile that you would not want to be
      publicly available.<br />
      2) Your active status or usage information is collected and displayed to
      other users (e.g. whether you are currently active on Freeland).<br />
      Sharing with third-party partners<br />
      By using Freeland, you agree to the third party use, sharing, and transfer
      of your personal information (both inside and outside of your
      jurisdiction) as prescribed in this Privacy Policy.<br />
      1) Measurement partners.<br />
      We may share information about you in anonymous and/or aggregated form
      with third parties for usage analytics (to help us better understand who
      is using our services and how), and for industry analysis, research, and
      other similar purposes.<br />
      2) Partners offering services in Freeland<br />
      Your information may be accessed and used by our service providers who are
      working with us in connection with the operation of our services. These
      service providers may have access to your information but only to the
      extent necessary to perform services on our behalf and are obligated not
      to disclose that information or use it for any other purposes. These
      providers will collect the information sent by your mobile phone, tablet
      or desktop computer, and their use of that information is governed by
      their applicable privacy policy.<br />
      3) Law enforcement<br />
      We may share information about you if we are (or if we believe we are)
      required by law or legal process (such as a subpoena, warrant or court
      order), if we have to respond to a lawful request from legal authorities
      to disclose such information, or if we need to enforce or apply this
      Privacy Policy, our Terms of Service or our other policies.<br />
      4) New owner<br />
      If we are acquired by a third party or may re-organise our group
      structure, your information may be transferred within our group and/or to
      a third party who will continue to operate Freeland or similar services
      under either this Privacy Policy or a different privacy policy which will
      be notified to you. The party to which your personal information is
      transferred may be located, and use your Information, outside of your
      jurisdiction.<br />
      Other than as permitted under this Privacy Policy or as otherwise
      consented to by you or as required by law or legal process, we will not
      transfer your personal information to any other third parties for their
      processing for any purposes.<br />
      3.4 Security Measures We Take to Protect Your Information<br />
      We employ administrative, physical and electronic measures designed to
      protect your information from unauthorized access, however, despite these
      efforts, no security measures are perfect or impenetrable and no method of
      data transmission can be guaranteed against any interception or other type
      of misuse. In the event that your personal information is compromised as a
      result of a breach of security, we will promptly notify you if your
      personal information has been compromised, as required by applicable
      law.<br />
      Where we do share your personal information with any third parties, we
      will use reasonable efforts to ensure that such third parties only use
      your personal information: (i) in compliance with this Privacy Policy; and
      (ii) subject to any other instructions we give them, including any
      appropriate confidentiality and security measures that we implement.<br />
      How to Access Your Information<br />
      You can access and modify the information you provided to us through your
      account.<br />
      If you believe your information posted on Freeland is not authentic or
      accurate and can provide us with reasonable proof regarding this, you can
      require us via the contact information provided in this Privacy Policy to
      correct these unauthentic or inaccurate information and we will use
      reasonable efforts to honour your request; however, we may retain an
      archived copy of your information as required by law and/or for record
      keeping purposes.<br />
      Once your account is closed or deleted, you will no longer have access to
      your message history, preferences or any other information associated with
      your use of our services. Even after you remove information from your
      account or profile, copies of that information may remain viewable
      elsewhere, to the extent it has been shared with others, it was otherwise
      distributed pursuant to your privacy settings, or it was copied or stored
      by other users. Additionally, we will retain your personal data even after
      you have closed your account if reasonably necessary to comply with our
      legal obligations (including law enforcement requests), meet regulatory
      requirements, resolve disputes, maintain security, prevent fraud and
      abuse, or enforce the Terms of Service. We will retain de-personalized
      information after your account has been closed.<br />
      In some cases, we may not be able to update, amend or remove your personal
      information, in which case we will let you know why we are unable to do
      so, for example, certain requests may require disproportionate technical
      effort or extremely impractical, or are prohibited by law.<br />
      Since Freeland will be used in multiple jurisdictions and we may not be
      possible to identify all the inconsistency between certain terms of this
      Privacy Policy and the applicable law in your jurisdiction, thus you agree
      to notify us once such inconsistency is identified by you. Under this
      circumstance we will take necessary methods to eliminate such
      inconsistency, including deleting these personal information improperly
      collected or used by us.<br />
      Contacting Us<br />
      If you have any questions about our Privacy Policy or any privacy-related
      matters, please contact us via email at 【contact@crosslinkin.com】.<br />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  mounted() {},
  methods: {},
}
</script>

<style lang="scss" scoped src="./privacy.scss"></style>
