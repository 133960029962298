import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Content from '@/components/Content/Content'
import Privacy from '@/components/Privacy/Privacy'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    children: [
      {
        path: '/',
        name: 'main',
        component: Content,
      },
      {
        path: 'privacy',
        name: 'privacy',
        component: Privacy,
      },
    ],
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
