<template>
  <div class="nav">
    <ul>
      <li
        class="item"
        :style="{ color }"
        v-for="(item, index) in hrefList"
        :key="index"
        @click="intoLocal(index)"
      >
        {{ item }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: ['hrefList', 'color'],
  data() {
    return {}
  },
  methods: {
    intoLocal(index) {
      document.getElementsByClassName('header_nav')[0].classList.add('navbg')
      console.log(index)
      if (index == 0) {
        this.$router.push({
          path: '/',
          query: {
            about: 1,
          },
        })
        return
      }
      if (index == 1) {
        this.$router.push({
          path: '/',
          query: {
            contactus: 1,
          },
        })
        return
      }
      if (index == 2) {
        this.$router.push({
          path: '/privacy',
        })
        return
      }
    },
  },
}
</script>

<style lang="scss" scoped src="./nav.scss"></style>
