<template>
  <div class="footer">
    <div class="wrap">
      <div class="footernav">
        <div class="f_logo">
          <img src="../img/logo1.png" alt="" />Freeland.ai
        </div>
        <div class="r">
          <Nav class="rnav" color="#28282F" :hrefList="hrefList"></Nav>
        </div>
      </div>
      <div class="footerinfo">
        <p></p>
        <p>© 2023 Freeland.ai All Rights Reserved.</p>
      </div>
    </div>
  </div>
</template>

<script>
import Nav from '@/components/Nav/Nav.vue'
export default {
  data() {
    return {
      hrefList: ['About Us', 'Contact Us', 'Privacy'],
    }
  },
  components: {
    Nav,
  },
}
</script>

<style lang="scss" scoped src="./footer.scss"></style>
